import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import "./VfiCustomSelect.scss";

export default function VfiCustomSelect(props) {
  return (
    <div className={"vfi-custom-select-wrap " + (props.error ? "error " : "") + (props.className ?? "")}>
      <Select {...props} className={"vfi-custom-select"} />
      <FontAwesomeIcon className="chevron" icon={faChevronDown} />
    </div>
  );
}
