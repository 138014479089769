import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import "./MediaChoose.scss";
import MediaDisplay from "../../MediaDisplay/MediaDisplay";

class MediaChoose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBottom: false,
    };
    this.userContents = [];
    this.axios = require("axios");
  }

  handleUserData = (response) => {
    this.userContents = [];
    response.data.forEach((e) => {
      // this.userContents.unshift(pushUsers(e));
      this.userContents.unshift(e);
    });
    this.setState({});
  };

  render() {
    if (this.props.data.call) {
      this.props.data.call();
    }
    return (
      <div style={{ height: "100%" }}>
        <div className="close-overlay">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              this.props.close();
            }}
          />
        </div>
        <MediaDisplay
          site={this.props.data.siteId ?? this.props.site} // Use defined siteId if it's defined
          openEdit={this.props.openOverlay}
          onInsertClick={(e) => {
            if (this.props.callbackArg) {
              this.props.callbackArg(e);
            }

            if (this.props.data.singleMedia) {
              if (this.props.data.unique) {
                e[0].unique = this.props.data.unique;
              }
              this.props.returnValue(e[0]);
              this.props.close(e[0]);
            } else {
              if (this.props.data.unique) {
                e = e.map((element) => {
                  element.unique = this.props.data.unique;
                  return element;
                });
              }

              if (this.props.data.singleMedia) {
                this.props.returnValue(e[0]);
                this.props.close(e[0]);
              } else {
                this.props.returnValue(e);
                this.props.close(e);
              }
            }
            if (this.props.data.callbackArg) {
              // There is a callback argument, add it to the return
              e[0].callbackArg = this.props.data.callbackArg;
            }
          }}
          addOne={this.props.addOne}
          setSiteRef={this.props.setSiteRef}
          filter={this.props.data.filter}
          callbackArg={this.props.data.callbackArg || null}
          singleMedia={this.props.data.singleMedia}
          showMore={this.props.showMore}
          setBottom={this.props.setBottom}
          selectedMedias={this.props?.data.selectedMedias}
          handleUserData={this.handleUserData}
        />
      </div>
    );
  }
}
export default MediaChoose;
