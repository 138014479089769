import { default as NewPasswordComponent } from "src/Components/Login/NewPassword";

import styles from "./NewPassword.module.scss";
import { useEffect, useState } from "react";
import { useRouterStore } from "src/Components/Router/routerStore";

export default function NewPassword() {
  // const [hash, setHash] = useState<undefined | string>("");
  // const [email, setEmail] = useState<undefined | string>("");

  const { get_parameters } = useRouterStore();
  const hash = get_parameters?.hash;
  const email = get_parameters?.email;

  // Why? get_parameters is already a state.
  // This is an unecessary use of a useEffect, limit use of useEffects and useStates
  // as these lower performance and can have unintended side-effects.
  // useEffect(() => {
  //   setHash(get_parameters?.hash);
  //   setEmail(get_parameters?.email);
  // }, [get_parameters]);

  if (hash === undefined) {
    return <div className={styles.container}>Reset key not found</div>;
  }

  return (
    <div className={styles.container}>
      {/* <NewPassword /> */}
      <NewPasswordComponent hash={hash} email={email} />
    </div>
  );
}
