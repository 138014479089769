import { faArrowLeft, faArrowRight, faLink, faPen, faPlus, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import OneMedia from "../OneMedia/OneMedia";
import "./VfiCarousel.scss";
import sliderLeft from "../../images/slider-left.svg";
import sliderRight from "../../images/slider-right.svg";
import noImage from "../../images/empty.jpg";
import VfiInputText from "../../VfiInputText/VfiInputText";
import { faTh } from "@fortawesome/pro-solid-svg-icons";
import ReactResizeDetector from "react-resize-detector";
import languages from "../language.json";
import LinkToEdit from "./LinkToEdit";
import ImageSettings from "./ImageSettings";
import cloneDeep from "lodash.clonedeep";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import SideDescription from "./SideDescription";
import { isArray } from "lodash";
import VfiDivInput from "../../VfiDivInput";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import RemoveMedia from "./components/RemoveMedia/RemoveMedia";

// export function moduleOptions(index,contentData,setContentData){
//   const language = localStorage.getItem("language") ? localStorage.getItem("language") : 'english';
//   const lang = languages[language].vfi_carousel.m_options;
//   return [
//     {
//       is: "choice",
//       icon: faPhotoVideo,
//       text: lang.into_default,
//       onClick: () => {
//         let newContentData = Object.assign([], contentData);
//         newContentData[index].galleryType = "slider_default";
//         setContentData(newContentData);
//       },
//       appear:()=>contentData[index].galleryType !== "slider_default"
//     },
//     {
//       is: "choice",
//       icon: faPhotoVideo,
//       text: lang.with_thumbnails,
//       onClick: () => {
//         let newContentData = Object.assign([], contentData);
//         newContentData[index].galleryType = "slider_with_thumb";
//         setContentData(newContentData);
//       },
//       appear:()=>contentData[index].galleryType !== "slider_with_thumb"
//     },
//     {
//       is: "choice",
//       icon: faPhotoVideo,
//       text: lang.carousel_slider,
//       onClick: () => {
//         let newContentData = Object.assign([], contentData);
//         newContentData[index].galleryType = "slider_carousel";
//         setContentData(newContentData);
//       },
//       appear:()=>contentData[index].galleryType !== "slider_carousel"
//     },
//     {
//       is: "choice",
//       icon: faPhotoVideo,
//       text: lang.grids_2,
//       onClick: () => {
//         let newContentData = Object.assign([], contentData);
//         newContentData[index].galleryType = "wall_grid_2";
//         setContentData(newContentData);
//       },
//       appear:()=>contentData[index].galleryType !== "wall_grid_2"
//     },
//     {
//       is: "choice",
//       icon: faPhotoVideo,
//       text: lang.grids_3,
//       onClick: () => {
//         let newContentData = Object.assign([], contentData);
//         newContentData[index].galleryType = "wall_grid_3";
//         setContentData(newContentData);
//       },
//       appear:()=>contentData[index].galleryType !== "wall_grid_3"
//     },
//     {
//       is: "choice",
//       icon: faPhotoVideo,
//       text: lang.flexible_sizes,
//       onClick: () => {
//         let newContentData = Object.assign([], contentData);
//         newContentData[index].galleryType = "flex_view";
//         setContentData(newContentData);
//       },
//       appear:()=>contentData[index].galleryType !== "flex_view"
//     },
//   ];
// }

const OneOfGalleryMedium = ({
  media,
  change,
  language,
  index,
  itemNr,
  setOptionsList,
  openOverlay,
  closeOptionsList,
  dataKey,
  openInfo,
  selectedLanguage,
  removeIndex,
  show_text,
  changeText,
  langJson,
  caption,
  inEdit,
  editPage,
  siteRef,
  isArticle,
}) => {
  const clickable = () =>
    (media.link_option === "page" && media.page_id) || (media.link_option === "external_url" && media.external_link);

  const { addOverlay } = overlayStore((state) => state);

  return (
    // <div className={"button" + (!inEdit && clickable(element) ? " active" : "")} onClick={()=>{
    //   if(!inEdit && clickable())
    //   {
    //     if(element.link_option === "external_url")
    //     {
    //       window.open(element.external_link,"_blank");
    //     }
    //     if(element.link_option === "page")
    //     {
    //       props.editPage(element.page_id);
    //     }
    //   }
    // }}>

    <div
      className={"grid-content item" + itemNr + (!inEdit && clickable(media) ? " active" : "")}
      onClick={() => {
        if (!inEdit && clickable()) {
          if (media.link_option === "external_url") {
            window.open(media.external_link, "_blank");
          }
          if (media.link_option === "page") {
            editPage(media.page_id);
          }
        }
      }}
    >
      <OneMedia
        media={media}
        disableChange={true}
        // imgFormat="thumbnail"
        videoThumbnail={false}
      />
      <div className="top-choices">
        <div
          className="choice change-page"
          onClick={(e) => {
            setOptionsList(
              <SideDescription
                key={`${index} ${dataKey}`}
                values={media}
                openOverlay={openOverlay}
                close={closeOptionsList}
                change={(e) => {
                  change(index, e);
                }}
                openInfo={openInfo}
                index={index}
                siteRef={siteRef}
                isArticle={isArticle}
              />,
              { x: e.pageX - 210, y: e.pageY + 30 },
              true,
              false
            );
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </div>

        {/* <div
          className="choice change-page"
          onClick={(e) => {
            setOptionsList(
              <LinkToEdit
                values={media}
                openOverlay={openOverlay}
                close={closeOptionsList}
                selectedLanguage={selectedLanguage}
                change={(e) => {
                  change(index, e);
                }}
              />,
              { x: e.pageX - 210, y: e.pageY + 30 },
              true
            );
          }}
        >
          <FontAwesomeIcon icon={faLink} />
        </div> */}

        <div
          className="choice remove"
          onClick={() => {
            addOverlay("card", (close) => {
              return (
                <RemoveMedia
                  close={close}
                  removeMedia={() => {
                    removeIndex(index);
                  }}
                />
              );
            });
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      </div>
      {/* {show_text && (
        <div className="medium-text">
          <VfiInputText
            className="headline"
            defaultValue={media.headline}
            onBlur={(e) => {
              changeText(index, "headline", e.target.value);
            }}
            placeholder={langJson.insert_headline}
          />
          <VfiInputText
            className="excerpt"
            defaultValue={media.excerpt}
            onBlur={(e) => {
              changeText(index, "excerpt", e.target.value);
            }}
            placeholder={langJson.insert_text}
          />
        </div>
      )} */}
      {caption === "on_hover" ? (
        <OnHoverCaption
          value={language.individual_caption_text}
          onBlur={(e) => changeText(index, "individual_caption_text", e)}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const HighlightBoxes = (props) => {
  return (
    <div className="wrap-highlight-boxes">
      {props.valueText.map((element, i) => {
        return (
          <div>
            {props.contentEditEnabled && (
              <div
                onClick={() => {
                  props.addRemove(props.valueText, "remove");
                }}
                className="remove"
              >
                <FontAwesomeIcon color="red" icon={faTrashAlt} />
              </div>
            )}
            <VfiCKeditor
              key={i}
              className="on-hover-text"
              value={props.valueText.length - 1 >= i ? props.valueText[i] : ""}
              onBlur={(e) => {
                props.onBlur(e, "text", i);
              }}
              placeholder="Insert text..."
            />
            <div className="button">
              <div
                onBlur={(e) => {
                  props.onBlur(e, "button", i);
                }}
                suppressContentEditableWarning={true}
                contentEditable="true"
              >
                {props.valueButton.length - 1 >= i ? props.valueButton[i] : ""}
              </div>
              <div
                className="link"
                onClick={(e) => {
                  props.linkOptions(e, i);
                }}
              >
                <FontAwesomeIcon icon={faLink} />
              </div>
            </div>
          </div>
        );
      })}
      {props.valueText.length < 3 && props.contentEditEnabled && (
        <div
          onClick={() => {
            props.addRemove(props.valueText, "add");
          }}
          className="add_new_highlight_box"
        >
          <FontAwesomeIcon icon={faPlus} />
        </div>
      )}
    </div>
  );
};

const OnHoverCaption = (props) => {
  return (
    <div className="on-hover-caption">
      <VfiCKeditor
        key={props.value}
        className="on-hover-text"
        value={props.value ?? ""}
        onBlur={props.onBlur}
        placeholder="Insert text..."
      />
      <div className="the-i">i</div>
    </div>
  );
};

const CaptionIndividual = (props) => {
  return (
    <div className="individual-caption">
      <VfiCKeditor className="individual-text" value={props.value} onBlur={props.onBlur} placeholder="Insert text..." />
    </div>
  );
};

export default function VfiCarousel(props) {
  const { data, inEdit, editPage, isArticle } = props;
  const language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";
  const lang = languages[language].vfi_carousel;
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [updateTime, setUpdateTime] = useState(0);
  const thumbImages = useRef();
  let theThumbScroll;

  const { addOverlay } = overlayStore((state) => state);

  const [showAllChunks, setShowAllChunks] = useState(false);

  let clearIntervalEvent = () => {
    clearInterval(theThumbScroll);
  };
  useEffect(() => {
    window.document.addEventListener("mouseup", clearIntervalEvent);
    return () => {
      window.document.removeEventListener("mouseup", clearIntervalEvent);
    };
  });

  useEffect(() => {
    if (props.media.length > 0)
      if (carouselIndex > props.media.length - 1) {
        setCarouselIndex(carouselIndex - 1);
      }
  }, [props.media, carouselIndex]);

  let mediaChunks = [];
  let collapsedShown = 3;
  if (props.galleryType === "wall_grid") {
    if (data.wall_grid_amount) {
      collapsedShown = parseInt(data.wall_grid_amount);
    }

    for (let i = 0; i < props.media.length; i += collapsedShown) {
      mediaChunks.push(props.media.slice(i, i + collapsedShown));
    }
  }

  const goLeft = () => {
    if (carouselIndex === 0) setCarouselIndex(props.media.length - 1);
    else setCarouselIndex(carouselIndex - 1);
  };

  const goRight = () => {
    if (carouselIndex === props.media.length - 1) setCarouselIndex(0);
    else setCarouselIndex(carouselIndex + 1);
  };

  let isGrid = false;
  let isFlex = false;

  const changeHighlight = (index, key, value, i, highlight) => {
    let languages = cloneDeep(props.data.languages);
    languages[index][key] = isArray(languages[index][key]) ? languages[index][key] : [];
    languages[index][key][i] = value;
    if (value === "add") {
      highlight.push("");
      languages[index][key] = highlight;
    }
    if (value === "remove") {
      highlight.splice(i, 1);
      languages[index][key] = highlight;
    }

    props.onChangeObj({ ...props.data, languages });
  };
  const changeText = (index, key, value) => {
    let languages = cloneDeep(props.data.languages);
    languages[index][key] = value;
    props.onChangeObj({ ...props.data, languages });
  };

  const changeAllKeysInText = (key, value) => {
    let languages = cloneDeep(props.data.languages);
    for (let i = 0; i < props.media.length; i++) {
      languages[i][key] = value;
    }
    props.onChangeObj({ ...props.data, languages });
  };

  const info = (element, i) => {
    const clickable = () =>
      (element.link_option === "page" && element.page_id) ||
      (element.link_option === "external_url" && element.external_link);
    return (
      <div className="info">
        {props.data.show_text ? (
          <div>
            <VfiDivInput
              className="headline"
              defaultValue={element.headline}
              onBlur={(e) => {
                changeText(i, "headline", e.target.innerHTML);
              }}
              placeholder={lang.insert_headline}
            />
            <VfiDivInput
              className="excerpt"
              defaultValue={element.excerpt}
              onBlur={(e) => changeText(i, "excerpt", e.target.innerHTML)}
              placeholder={lang.insert_text}
            />
          </div>
        ) : (
          ""
        )}
        {props.data.link_appearance === "on_buttons" && element.show_link ? (
          <div className="button-wrap">
            <div
              className={"button" + (!inEdit && clickable(element) ? " active" : "")}
              onClick={() => {
                if (!inEdit && clickable()) {
                  if (element.link_option === "external_url") {
                    window.open(element.external_link, "_blank");
                  }
                  if (element.link_option === "page") {
                    props.editPage(element.page_id);
                  }
                }
              }}
            >
              <div
                className="link-to"
                onClick={(e) => {
                  props.setOptionsList(
                    <LinkToEdit
                      values={props.data.media[carouselIndex]}
                      change={(e) => {
                        let media = cloneDeep(props.data.media);
                        media[carouselIndex] = e;
                        props.onChangeObj({ ...props.data, media });
                      }}
                      openOverlay={props.openOverlay}
                      close={props.closeOptionsList}
                      selectedLanguage={props.selectedLanguage}
                      // headline="Select where the slide should be linked to."
                    />,
                    { x: e.pageX + 25, y: e.pageY - 265 / 2 },
                    true
                  );
                }}
              >
                <FontAwesomeIcon icon={faLink} />
              </div>
              <VfiInputText
                className="button-text"
                defaultValue={element.button_text}
                onChange={(e) => changeText(i, "button_text", e.target.value)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  if (props.galleryType === "wall_grid_2" || props.galleryType === "wall_grid_3" || props.galleryType === "wall_grid")
    isGrid = true;

  if (props.galleryType === "flex_view") isFlex = true;

  const moveMedium = (index = 0, moveOffset = 1) => {
    let copyData = cloneDeep(props.data);
    const medium = copyData.media[index];
    copyData.media.splice(index, 1);
    copyData.media.splice(index + moveOffset, 0, medium);
    const langObj = copyData.languages[index];
    copyData.languages.splice(index, 1);
    copyData.languages.splice(index + moveOffset, 0, langObj);
    props.onChangeObj(copyData);
  };

  const MiddleTop = (props2) => (
    <div className="middle-top">
      <div
        className="option"
        onClick={(e) => {
          props.setOptionsList(
            <ImageSettings
              key={updateTime}
              values={props.data.media[carouselIndex]}
              change={(e) => {
                let media = cloneDeep(props.data.media);
                media[carouselIndex] = e;
                props.onChangeObj({ ...props.data, media });
                setUpdateTime(new Date().getTime());
              }}
              openOverlay={props.openOverlay}
              close={props.closeOptionsList}
              selectedLanguage={props.selectedLanguage}
              openInfo={props.openInfo}
              siteRef={props.siteRef}
              index={carouselIndex}
              isArticle={isArticle}
            />,
            { x: e.pageX - 210, y: e.pageY + 30 },
            true,
            false
          );
        }}
      >
        <FontAwesomeIcon icon={faPen} />
      </div>
      <div
        className="option"
        onClick={() => {
          moveMedium(props2.index, -1);
        }}
        style={{ display: props2.index === 0 ? "none" : "grid" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
      <div
        className="option"
        onClick={() => {
          moveMedium(props2.index, 1);
        }}
        style={{
          display: props2.index === props.data.media.length - 1 ? "none" : "grid",
        }}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
      <div
        className="option red"
        onClick={() => {
          addOverlay("card", (close) => {
            return (
              <RemoveMedia
                close={close}
                removeMedia={() => {
                  props.removeMedia(carouselIndex);
                  if (carouselIndex === props.media.length - 1 && carouselIndex !== 0) {
                    setCarouselIndex(carouselIndex - 1);
                  }
                }}
              />
            );
          });
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </div>
    </div>
  );

  // No way to find out languages, if no media was added before save.
  if (!props.data.languages) {
    let languages = {};
    props.chosenLanguages.forEach((lang) => {
      languages[lang] = [];
    });
    props.onChangeObj({ ...props.data, languages });
  }

  // const [editModuleOffset,setEditModuleOffset] = useState(false);
  // useEffect(
  //   ()=>{
  //     if(props.data.caption === "on_hover")
  //     {
  //       props.onChangeObj({...props.data,editModuleOffset:{x:0,y:40}});
  //       // setEditModuleOffset(true);
  //     }
  //     if(props.data.caption !== "on_hover")
  //     {
  //       props.onChangeObj({...props.data,editModuleOffset:{x:0,y:0}});
  //       // setEditModuleOffset(false);
  //     }
  //   }
  //   ,[]
  // )

  return (
    <div
      style={props.style}
      className={
        "vfi-carousel " +
        (props.galleryType ? props.galleryType + " " : "") +
        (props.is_hero ? "disable-maxwidth " : "") +
        (props.className ? props.className : "")
      }
    >
      {props.galleryType === "slider_default" ||
      props.galleryType === "slider_with_thumb" ||
      props.galleryType === "slider_carousel" ||
      props.galleryType === "slider_with_highlight" ? (
        <div className="carousel-wrap">
          {(props.media.length > 1 && props.galleryType === "slider_default") ||
          props.galleryType === "slider_with_highlight" ? (
            <div className="left-arrow" onClick={goLeft}>
              <img src={sliderLeft} alt="slide-left" />
            </div>
          ) : (
            ""
          )}
          {(props.media.length > 1 && props.galleryType === "slider_default") ||
          props.galleryType === "slider_with_highlight" ? (
            <div className="right-arrow" onClick={goRight}>
              <img src={sliderRight} alt="slide-right" />
            </div>
          ) : (
            ""
          )}
          {props.galleryType === "slider_carousel" && props.media.length > 1 ? (
            <div className="top-on-carousel">
              <div className="top-left-arrow" onClick={goLeft}>
                <img src={sliderLeft} alt="left" />
              </div>
              <div className="top-right-arrow" onClick={goRight}>
                <img src={sliderRight} alt="right" />
              </div>
            </div>
          ) : (
            ""
          )}

          {props.galleryType === "slider_default" ||
          props.galleryType === "slider_with_thumb" ||
          props.galleryType === "slider_with_highlight" ? (
            <Carousel
              interval={null}
              //   fade={true}
              controls={false}
              activeIndex={carouselIndex}
              onSelect={(e) => {
                setCarouselIndex(e);
              }}
              keyboard={false}
              indicators={props.galleryType === "slider_default" && props.media.length > 1 ? true : false}
            >
              {props.media.length !== 0 ? (
                props.media.map((element, i) => {
                  return (
                    <Carousel.Item key={element.key}>
                      <OneMedia
                        siteRef={props.siteRef}
                        setSiteRef={props.setSiteRef}
                        openOverlay={props.openOverlay}
                        whatMedia={props.whatMedia}
                        media={element}
                        // onClick={() => {
                        //   // setModuleIndexInEdit(i);
                        // }}
                        disableChange={true}
                      />
                      {props.galleryType === "slider_with_highlight" && (
                        <HighlightBoxes
                          valueText={props.data.languages[i].individual_highlight_text ?? [...Array(1)]}
                          valueButton={props.data.languages[i].individual_highlight_button ?? [...Array(1)]}
                          contentEditEnabled={props.contentEditEnabled}
                          addRemove={(highlight, type) => {
                            changeHighlight(carouselIndex, "individual_highlight_text", type, -1, highlight);
                          }}
                          linkOptions={(e, j) => {
                            props.setOptionsList(
                              <LinkToEdit
                                values={
                                  isArray(props.data.languages[i].individual_highlight_link)
                                    ? props.data.languages[i].individual_highlight_link.length - 1 >= j
                                      ? props.data.languages[i].individual_highlight_link[j]
                                      : { link_option: "none" }
                                    : { link_option: "none" }
                                }
                                change={(e) => {
                                  changeHighlight(carouselIndex, "individual_highlight_link", e, j);
                                }}
                                openOverlay={props.openOverlay}
                                close={props.closeOptionsList}
                                selectedLanguage={props.selectedLanguage}
                              />,
                              { x: e.pageX + 25, y: e.pageY - 265 / 2 },
                              true
                            );
                          }}
                          data={props.data}
                          onBlur={(e, type, i) => {
                            let value = "";
                            value = e;
                            if (type === "button") {
                              value = e.currentTarget.textContent;
                              changeHighlight(carouselIndex, "individual_highlight_button", value, i);
                            }
                            if (type === "text") {
                              value = e;
                              changeHighlight(carouselIndex, "individual_highlight_text", value, i);
                            }
                          }}
                        />
                      )}
                      {props.data.caption === "on_hover" ? (
                        <OnHoverCaption
                          value={props.data.languages[i].individual_caption_text}
                          onBlur={(e) => changeText(carouselIndex, "individual_caption_text", e)}
                        />
                      ) : (
                        ""
                      )}

                      {props.data.caption === "under_images_individual" ||
                      props.data.caption === "under_images_one_for_all" ? (
                        <CaptionIndividual
                          value={props.data.languages[i].individual_caption_text}
                          onBlur={(e) => {
                            if (props.data.caption === "under_images_individual")
                              changeText(carouselIndex, "individual_caption_text", e);

                            if (props.data.caption === "under_images_one_for_all")
                              changeAllKeysInText("individual_caption_text", e);
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <MiddleTop index={i} />
                      {info(element, i)}
                    </Carousel.Item>
                  );
                })
              ) : (
                <Carousel.Item>
                  <div className="no-image" alt={"none"}></div>
                </Carousel.Item>
              )}
            </Carousel>
          ) : (
            ""
          )}
          {props.galleryType === "slider_carousel" ? (
            <div className="real-carousel-wrap">
              <ReactResizeDetector handleWidth handleHeight>
                {({ width, height }) => (
                  <div className="real-carousel" style={{ left: (-width - 20) * carouselIndex + "px" }}>
                    {props.media.map((element, i) => {
                      return (
                        <div
                          className="one-real-wrap"
                          key={element.key}
                          style={{ opacity: carouselIndex === i ? 1 : 0.5 }}
                        >
                          {/* {props.data.caption === "on_hover" ? <OnHoverCaption value={element.onHoverText} /> : ""} */}
                          <MiddleTop index={i} />
                          <OneMedia
                            siteRef={props.siteRef}
                            setSiteRef={props.setSiteRef}
                            openOverlay={props.openOverlay}
                            whatMedia={props.whatMedia}
                            media={element}
                            disableChange={true}
                            onClick={() => {
                              setCarouselIndex(i);
                            }}
                          />
                          {props.data.caption === "on_hover" ? (
                            <OnHoverCaption
                              value={props.data.languages[i].individual_caption_text}
                              onBlur={(e) => changeText(carouselIndex, "individual_caption_text", e)}
                            />
                          ) : (
                            ""
                          )}
                          {props.data.caption === "under_images_individual" ||
                          props.data.caption === "under_images_one_for_all" ? (
                            <CaptionIndividual
                              value={props.data.languages[i].individual_caption_text}
                              onBlur={(e) => {
                                if (props.data.caption === "under_images_individual")
                                  changeText(carouselIndex, "individual_caption_text", e);

                                if (props.data.caption === "under_images_one_for_all")
                                  changeAllKeysInText("individual_caption_text", e);
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {info(element, i)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </ReactResizeDetector>
              {props.media.length === 0 ? (
                <div className="no-image">
                  {/* <img src={noImage} alt="no-media" /> */}
                  <div className="add-media" onClick={() => props.addMedia()}>
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {/* <div className="add-media" onClick={() => props.addMedia()}>
              <FontAwesomeIcon icon={faPlus} />
            </div> */}
          {/* {props.media.length !== 0 ? (
              <div
                className="remove-media"
                title="Remove this medium"
                onClick={() => {
                  props.removeMedia(carouselIndex);
                  if (
                    carouselIndex === props.media.length - 1 &&
                    carouselIndex !== 0
                  ) {
                    setCarouselIndex(carouselIndex - 1);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            ) : (
              ""
            )} */}
        </div>
      ) : (
        ""
      )}
      {props.galleryType === "slider_with_thumb" && props.media.length > 1 ? (
        <div className="thumb-wrap">
          <div
            className="left-arrow"
            onMouseDown={(e) => {
              if (window.event.which === 1)
                theThumbScroll = setInterval(() => {
                  thumbImages.current.scrollLeft -= 5;
                }, 10);
              else clearIntervalEvent();
            }}
            onMouseLeave={() => clearIntervalEvent()}
            draggable="false"
            onDragStart={(e) => {
              e.preventDefault();
            }}
            onClick={goLeft}
          >
            <img src={sliderLeft} alt={"slide-left"} />
          </div>
          <div className="thumb-images" ref={thumbImages}>
            {props.media.map((element, i) => {
              return (
                <div key={element.key}>
                  <OneMedia
                    media={element}
                    onClick={() => {
                      setCarouselIndex(i);
                    }}
                    disableChange={true}
                    imgFormat="thumbnail"
                    videoThumbnail={true}
                  />
                </div>
              );
            })}
          </div>
          <div
            className="right-arrow"
            onMouseDown={(e) => {
              if (window.event.which === 1)
                theThumbScroll = setInterval(() => {
                  thumbImages.current.scrollLeft += 5;
                }, 10);
              else clearIntervalEvent();
            }}
            onMouseLeave={() => clearIntervalEvent()}
            draggable="false"
            onDragStart={(e) => {
              e.preventDefault();
            }}
            onClick={goRight}
          >
            <img src={sliderRight} alt="slide-right" />
          </div>
        </div>
      ) : (
        ""
      )}
      {isGrid || isFlex ? (
        props.media.length > 0 ? (
          <div className="wall-grid">
            {isGrid ? (
              <div className="grids-wrap">
                {mediaChunks.map((elementChunk, i) => {
                  if (data.gallery_expand && !showAllChunks && i > 0) {
                    return "";
                  }

                  return (
                    <div
                      className={`grids amount-${data.wall_grid_amount ?? 3}`}
                      key={"chunk " + (elementChunk[0] ? elementChunk[0].key : "empty")}
                    >
                      {elementChunk.map((eChunk, j) => {
                        if (j < (data.wall_grid_amount ?? 3)) {
                          // return <OneOfTheMedium key={e.key} media={e} index={i+1} />

                          const theI = i * collapsedShown + j;
                          return (
                            <OneOfGalleryMedium
                              index={theI}
                              change={(index, newMedia) => {
                                let media = cloneDeep(data.media);
                                media[index] = newMedia;
                                props.onChangeObj({ ...data, media });
                              }}
                              changeText={changeText}
                              key={eChunk.key}
                              media={eChunk}
                              caption={props.data.caption}
                              closeOptionsList={props.closeOptionsList}
                              dataKey={data.key}
                              itemNr={j + 1}
                              langJson={lang}
                              language={data.languages[theI]}
                              openInfo={props.openInfo}
                              openOverlay={props.openOverlay}
                              removeIndex={(theI) => {
                                let copyData = cloneDeep(props.data);
                                copyData.media.splice(theI, 1);
                                copyData.languages.splice(theI, 1);
                                // props.updateData(copyData);
                                props.onChangeObj(copyData);
                              }}
                              selectedLanguage={props.selectedLanguage}
                              setOptionsList={props.setOptionsList}
                              show_text={data.show_text}
                              inEdit={inEdit}
                              editPage={editPage}
                              siteRef={props.siteRef}
                              isArticle={isArticle}
                            />
                          );
                        } else {
                          return "";
                        }
                      })}
                      {/* {elementChunk[0] ? (
                        <OneOfTheMedium media={elementChunk[0]} index={1} />
                      ) : (
                        ""
                      )}
                      {elementChunk[1] ? (
                        <OneOfTheMedium media={elementChunk[1]} index={2} />
                      ) : (
                        ""
                      )}
                      {elementChunk[2] ? (
                        <OneOfTheMedium media={elementChunk[2]} index={3} />
                      ) : (
                        ""
                      )} */}
                    </div>
                  );
                })}
                {props.data.caption === "under_images_one_for_all" ? (
                  <CaptionIndividual
                    value={props.data.languages[0] ? props.data.languages[0].individual_caption_text : ""}
                    onBlur={(e) => {
                      if (props.data.caption === "under_images_individual")
                        changeText(carouselIndex, "individual_caption_text", e);

                      if (props.data.caption === "under_images_one_for_all")
                        changeAllKeysInText("individual_caption_text", e);
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {isFlex ? (
              <div className="flex-wrap">
                {props.media.map((element, i) =>
                  i < collapsedShown || showAllChunks ? (
                    <div className="flex-content">
                      <OneMedia
                        media={element}
                        disableChange={true}
                        // imgFormat="thumbnail"
                        videoThumbnail={false}
                      />
                      <div className="remove-media" onClick={() => props.removeMedia(i)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            ) : (
              ""
            )}
            {props.media.length > collapsedShown && data.gallery_expand ? (
              <div className="expand-grid" onClick={() => setShowAllChunks(!showAllChunks)}>
                <span className="text">{showAllChunks ? "Collapse" : "Show all " + props.media.length}</span>
                <FontAwesomeIcon icon={faTh} />
              </div>
            ) : (
              ""
            )}
            <div className="add-media" onClick={() => props.addMedia()}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        ) : (
          <div className="no-image">
            {/* <img src={noImage} alt="no-media" /> */}
            <div className="add-media" onClick={() => props.addMedia()}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
}
