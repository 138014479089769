import { useState, useEffect } from "react";
import env from "../../../../environment.json";
import { IMedia } from "../types";

export const useInitialImageSource = (
  media: IMedia,
  src: string,
  extension: string,
  imgFormat: string | null | undefined
) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null);

  useEffect(() => {
    const initialSrc = media.medium
      ? media.medium.large
      : src ??
        `${env.protocol}${env.env}/uploads/${media.file_name}${
          imgFormat ? `_${imgFormat}.` : ["gif", "svg"].includes(media.extension) ? "." : "_large."
        }${extension}`;

    setImgSrc(initialSrc);
  }, [media, src, extension, imgFormat]);

  return [imgSrc, setImgSrc];
};
