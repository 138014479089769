import { clone, cloneDeep } from "lodash";
import VfiCheckbox from "../../../VfiCheckbox";
import noImage from "../../../images/empty.jpg";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlt } from "@fortawesome/pro-regular-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import { sortList as hSortList } from "../../../helperFunctions";
import { searchFilter, whatsonContentPush } from "../../../helperFunctions";

export default function C_WhatsonPages(props) {
  const { headline, pages, show, data, onChange } = props;
  const [allChecked, setAllChecked] = useState(false);
  const [state, setState] = useState({
    sortedBy: "title",
    reverse: false,
  });
  const [shownWhatson, setShownWhatson] = useState(pages);
  const [sortedBy, setSortedBy] = useState("title");
  const [reverseSort, setReverseSort] = useState(false);

  const selectPage = (id) => {
    let whatson_chosen_ids = cloneDeep(data.whatson_chosen_ids);
    if (whatson_chosen_ids.find((wci) => wci === id)) {
      // FOUND, NOW REMOVE IT
      whatson_chosen_ids = whatson_chosen_ids.filter((wci) => wci !== id);
    } else {
      // NOT FOUND, NOW ADD IT
      whatson_chosen_ids.push(id);
    }
    onChange({ ...data, whatson_chosen_ids });
  };

  useEffect(() => {
    let finalWhatsonList = cloneDeep(pages);

    // finalWhatsonList = searchFilter(finalWhatsonList);
    const sortedList = hSortList(
      sortedBy,
      finalWhatsonList.map((e) => whatsonContentPush(e)),
      reverseSort,
      true
    );
    let newSorted = [];
    sortedList.forEach((element) => {
      newSorted.push(finalWhatsonList.find((e) => e.id === element.id));
    });
    setShownWhatson(newSorted);
  }, [sortedBy, reverseSort, pages]);

  const sortList = (sortBy) => {
    // SORT IN PROGRESS...
    if (sortBy === sortedBy) {
      setReverseSort(!reverseSort);
    } else {
      setSortedBy(sortBy);
      setReverseSort(false);
    }
  };

  return (
    <div className="c-whatson-pages">
      <div className={"the-list results" + (show ? " loaded" : "")}>
        <div className="top-margin" />
        <div className="whatson-table">
          <table>
            <thead>
              <tr>
                {headline && (
                  <>
                    <th
                      className={sortedBy === "title" ? "title sorted-by" : "title"}
                      onClick={() => {
                        sortList("title");
                        let reverse = false;
                        if (sortedBy === "title" && state.reverse === false) reverse = true;
                        setState({ sortedBy: "title", reverse: reverse });
                      }}
                    >
                      Title
                      <FontAwesomeIcon
                        icon={sortedBy === "title" ? faSortAltSorted : faSortAlt}
                        flip={"vertical"}
                        transform={state.reverse ? {} : { rotate: 180 }}
                      />
                    </th>
                    <th
                      className={sortedBy === "region" ? "region sorted-by" : "region"}
                      onClick={() => {
                        sortList("region");
                        let reverse = false;
                        if (sortedBy === "region" && state.reverse === false) reverse = true;
                        setState({ sortedBy: "region", reverse: reverse });
                      }}
                    >
                      Region
                      <FontAwesomeIcon
                        icon={sortedBy === "region" ? faSortAltSorted : faSortAlt}
                        flip={"vertical"}
                        transform={state.reverse ? {} : { rotate: 180 }}
                      />
                    </th>
                    <th>
                      <VfiCheckbox
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setAllChecked(checked);
                          if (checked) {
                            let wcidCopy = clone(data.whatson_chosen_ids);
                            pages.forEach((p) => {
                              wcidCopy.push(p.id);
                            });
                            wcidCopy = [...new Set(wcidCopy)];
                            onChange({ ...data, whatson_chosen_ids: wcidCopy });
                          } else {
                            onChange({ ...data, whatson_chosen_ids: [] });
                          }
                        }}
                        checked={allChecked}
                      />
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {show &&
                shownWhatson.map((page) => (
                  <tr
                    key={page.id}
                    className={"option " + (data.whatson_chosen_ids.find((f) => f === page.id) ? " active" : "")}
                    onClick={() => {
                      selectPage(page.id);
                    }}
                  >
                    <td className="inside-option">
                      <img
                        className="image"
                        src={page.id_media ? page.image.thumbnail : noImage}
                        alt="thumbnail of featured whatson"
                      />
                      <span className="title">{page.headline}</span>
                    </td>
                    <td className="region">
                      <span>{page.region_name}</span>
                    </td>
                    <td>
                      <VfiCheckbox
                        className="checkbox"
                        checked={data.whatson_chosen_ids.find((f) => f === page.id) ? true : false}
                        onChange={() => {
                          selectPage(page.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
